
@import 'variables';

//=============

.section {
  float: left;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container {
  float: left;
  width: 330px;
  max-width: 88%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

//=============

.wg-social {
  text-align: center;
  background-color: $s-bg-color;
  min-height: 100vh;
  float: left;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.logo {
  float: left;
  max-width: 100%;
  &--icon {
    width: 70px;
  }
  &--text {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 295px;
  }
}


//=============


.section--header {
  padding: 50px 0 0;
}

.section--more {
  flex-grow: 1;
}

main {
  float: left;
  width: 100%;
  position: relative;
  flex-grow: 1;
  isolation: isolate;
  display: flex;
  flex-direction: column;
}

.content {
  float: left;
  width: 100%;
  height: 500px;
  position: relative;
  flex-grow: 1;
  isolation: isolate;
  display: flex;
  flex-direction: column;
}

.content__wavy {
  width: 100%;
  height: 200px;
  animation-name: wavy;
  animation-duration: 8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out;
  animation-direction: reverse;
  background-color: red;
  margin-bottom: -100px;
}

.content__main {
  float: left;
  width: 100%;
  background-color: red;
  flex-grow: 1;
  box-sizing: border-box;
  z-index: 22;
  display: flex;
  flex-direction: column;
  align-items: center;
}

@keyframes wavy {
  0% ,100%   {
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 10.00%, 5.00% 10.31%, 10.00% 11.24%, 15.00% 12.71%, 20.00% 14.64%, 25.00% 16.91%, 30.00% 19.37%, 35.00% 21.87%, 40.00% 24.26%, 45.00% 26.37%, 50.00% 28.09%, 55.00% 29.30%, 60.00% 29.92%, 65.00% 29.92%, 70.00% 29.30%, 75.00% 28.09%, 80.00% 26.37%, 85.00% 24.26%, 90.00% 21.87%, 95.00% 19.37%, 100.00% 16.91%);
  }
  50% {
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 30.00%, 5.00% 29.69%, 10.00% 28.76%, 15.00% 27.29%, 20.00% 25.36%, 25.00% 23.09%, 30.00% 20.63%, 35.00% 18.13%, 40.00% 15.74%, 45.00% 13.63%, 50.00% 11.91%, 55.00% 10.70%, 60.00% 10.08%, 65.00% 10.08%, 70.00% 10.70%, 75.00% 11.91%, 80.00% 13.63%, 85.00% 15.74%, 90.00% 18.13%, 95.00% 20.63%, 100.00% 23.09%);
  }
}


@keyframes wavy2 {
  0%, 100% {
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 30.00%, 5.00% 29.69%, 10.00% 28.76%, 15.00% 27.29%, 20.00% 25.36%, 25.00% 23.09%, 30.00% 20.63%, 35.00% 18.13%, 40.00% 15.74%, 45.00% 13.63%, 50.00% 11.91%, 55.00% 10.70%, 60.00% 10.08%, 65.00% 10.08%, 70.00% 10.70%, 75.00% 11.91%, 80.00% 13.63%, 85.00% 15.74%, 90.00% 18.13%, 95.00% 20.63%, 100.00% 23.09%);
  }
  50%   {
    clip-path: polygon(100% 100%, 0% 100% , 0.00% 10.00%, 5.00% 10.31%, 10.00% 11.24%, 15.00% 12.71%, 20.00% 14.64%, 25.00% 16.91%, 30.00% 19.37%, 35.00% 21.87%, 40.00% 24.26%, 45.00% 26.37%, 50.00% 28.09%, 55.00% 29.30%, 60.00% 29.92%, 65.00% 29.92%, 70.00% 29.30%, 75.00% 28.09%, 80.00% 26.37%, 85.00% 24.26%, 90.00% 21.87%, 95.00% 19.37%, 100.00% 16.91%);
  }

}

//=============

.socialLinks {
  width: 100%;
  float: left;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: 20px;
}

.btn {
  width: 100%;
  appearance: none;
  display: inline-block;
  padding: 11px 40px 11px 20px;
  box-sizing: border-box;
  background-color: white;
  color: black;
  text-decoration: none;
  border-radius: 25px;
  text-align: left;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  font-weight: 600;
  background-size: 14px;
  background-position: calc(100% - 20px) 50%;
  background-repeat: no-repeat;
  background-image: url(/images/arrow-right-black.svg);
  transition: all .4s ease;
  @media (hover: hover) and (pointer: fine) {
    &:hover,
    &:focus {
      background-color: lightgrey;
      background-position: calc(100% - 15px) 50%;
    }
  } // Query 
}


.btn__icon {
  float: left;
  width: 20px;
  height: 20px;
  background-size: 100%;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-image: url(/images/icon-link-black.svg);
  &--youtube {
    background-image: url(/images/icon-youtube-black.svg);
  }
  &--tiktok {
    background-image: url(/images/icon-tiktok-black.svg);
  }
  &--instagram {
    background-image: url(/images/icon-instagram-black.svg);
  }
  &--facebook {
    background-image: url(/images/icon-facebook-black.svg);
  }
}